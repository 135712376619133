<template>
  <div>
    <div class="text-center mb-4">
      <span class="text-h6 text--secondary">
        <img
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          alt="Vuetify Logo"
          width="24"
          style="vertical-align: middle;"
          class="mr-1"
        />
      </span>
    </div>
    <div class="transition-wrapper">
      <div>
        <div class="text-center">
          <h1 class="headline mb-2">
            Reset Password
          </h1>
          <span v-if="!$route.params.code" class="d-inline-block mb-8"
            >Use oure email to reset password</span
          >
          <span v-else class="d-inline-block mb-8">Enter a new password</span>
        </div>

        <v-form v-if="!$route.params.code">
          <v-text-field
            ref="input"
            v-model="email"
            class="mb-2"
            label="Email"
            name="login"
            type="text"
            hide-details="auto"
            outlined
            :error="$v.email.$invalid && email !== ''"
          />

          <router-link
            to="/login"
            class="d-inline-block text-body-2 text-decoration-none font-weight-bold mb-8"
            >Login</router-link
          >
        </v-form>

        <v-form v-else>
          <v-text-field
            ref="input"
            v-model="password"
            class="mb-2"
            label="Password"
            name="password"
            type="password"
            hide-details="auto"
            outlined
            :error="$v.password.$invalid && password !== ''"
          />

          <v-text-field
            ref="input"
            v-model="confirmPassword"
            class="mb-2"
            label="Confirm password"
            name="password"
            type="password"
            hide-details="auto"
            outlined
            :error="$v.confirmPassword.$invalid && confirmPassword !== ''"
          />

          <router-link
            to="/login"
            class="d-inline-block text-body-2 text-decoration-none font-weight-bold mb-8"
            >Login</router-link
          >
        </v-form>

        <div class="d-flex justify-space-between">
          <div />
          <v-btn
            v-if="!$route.params.code"
            class="text-none"
            style="min-width: 88px;"
            color="primary"
            depressed
            :disabled="$v.email.$invalid"
            @click="submitRecoveryPassword"
          >
            RESET
          </v-btn>
          <v-btn
            v-else
            class="text-none"
            style="min-width: 88px;"
            color="primary"
            depressed
            :disabled="$v.confirmPassword.$invalid || $v.password.$invalid"
            @click="submitNewPassword"
          >
            SUBMIT
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  components: {},

  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6)
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs("password")
    }
  },

  data() {
    return {
      show: false,
      email: "",
      error: "",
      password: "",
      confirmPassword: ""
    };
  },

  methods: {
    ...mapActions({
      recoverPassword: "auth/recoverPassword",
      resetPassword: "auth/resetPassword"
    }),

    async submitRecoveryPassword() {
      try {
        await this.recoverPassword({ email: this.email });
        this.$toast.success("Email has been send!");
        this.$router.push("/login");
      } catch (error) {
        this.$toast.error(error);
      }
    },

    async submitNewPassword() {
      try {
        await this.resetPassword({
          token: this.$route.params.code,
          password: this.password,
          passwordRepeat: this.confirmPassword
        });
        this.$toast.success("Password has been changed!");
        this.$router.push("/login");
      } catch (error) {
        this.$toast.error(error);
      }
    }
  }
};
</script>
